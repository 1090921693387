<template>
  <svg id="Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>professional-network-linkedin</title>
    <rect x="2" y="8.5" width="5" height="14" rx="0.5" ry="0.5" />
    <ellipse cx="4.48" cy="4" rx="2.48" ry="2.5" />
    <path
      d="M18.5,22.5h3A.5.5,0,0,0,22,22V13.6C22,9.83,19.87,8,16.89,8a4.21,4.21,0,0,0-3.17,1.27A.41.41,0,0,1,13,9a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,9,9V22a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,13,22V14.5a2.5,2.5,0,0,1,5,0V22A.5.5,0,0,0,18.5,22.5Z"
    />
  </svg>
</template>
